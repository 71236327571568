<template>
  <main class="main services-open-page">
    <div class="container" v-if="brand">
      <BreadcrumbsComponent :title="pageTitle" />
      <section class="prg">
        <div class="prg__inner-fix">
          <div class="prg__left">
            <div class="prg__info" v-if="!!description">
              <EditorJSComponent :text="description" />
            </div>
          </div>
          <div class="prg__right" v-if="brand.head_img">
            <img
              loading="lazy"
              decoding="async"
              :src="$store.state.api + brand.head_img.img.url"
              :alt="brand.head_img.img.alt"
            />
          </div>
        </div>
      </section>
      <h2 class="prg__tabs__title" v-if="programs && programs.length">Доступные программы</h2>
      <div class="inv__tabs" v-if="programs && programs.length">
        <div class="inv__tabs__list" :class="{ 'flickity-ready': flickityReady }">
          <div
            :class="{ active: activeTab === index }"
            @click="activeTab = index"
            :key="index"
            class="inv__tabs__list-item"
            v-for="(prg, index) in programs"
          >
            <span v-if="prg">{{ prg.title }}</span>
          </div>
        </div>
        <div class="inv__tabs__content">
          <EditorJSComponent :text="JSON.parse(programs[activeTab].text)" />
          <template v-if="options.length">
            <h3 class="inv__tabs__title">Доступные опции</h3>
            <div class="prg__tabs__buttons">
              <template v-for="(option, index) in options">
                <router-link
                  :to="{ name: 'option', params: { url: option.link } }"
                  :key="index"
                  v-if="option && option.tooltip"
                  v-tippy="{ followCursor: false, placement: 'top' }"
                  :content="option.tooltip"
                  class="btn-dark"
                >
                  {{ `option`.title }}
                </router-link>
                <router-link
                  :to="{ name: 'option', params: { url: option.link } }"
                  :key="index"
                  v-else-if="option"
                  class="btn-dark"
                >
                  {{ option.title }}
                </router-link>
              </template>
            </div>
          </template>
          <template v-if="conditions.length">
            <h3 class="inv__tabs__title">Особенности предложения компании «Лизинг-Трейд»</h3>
            <ul class="prg__conditions__list">
              <li v-for="(condition, index) in conditions" :key="index">
                <div class="prg__conditions__content" v-if="condition">
                  <span class="prg__conditions__date" v-if="condition.from">{{ condition.from }}</span>
                  <span class="prg__conditions__number" v-if="condition.value">{{ condition.value }}</span>
                  <span class="prg__conditions__modifier" v-if="condition.mod">{{ condition.mod }}</span>
                  <h3 class="prg__conditions__name">{{ condition.title }}</h3>
                </div>
                <h3 class="prg__conditions__name" v-if="condition">{{ condition.title }}</h3>
              </li>
            </ul>
          </template>
          <router-link :to="{ name: 'program', params: { url: programs[activeTab].link } }" class="btn"
            >Подробнее о программе
          </router-link>
        </div>
      </div>
    </div>
    <CalculatorComponent :vehicles="$store.state.brand_page.vehicles" v-if="$store.state.brand_page" />
    <section class="container">
      <div class="about prg__about_block" v-if="!!article">
        <EditorJSComponent :text="article" />
      </div>
      <BrandsItemSlider
        v-if="products.length"
        :brands="products"
        :vehicle="$route.params.vehicle"
        title="Другие автомобили в лизинг"
      />
    </section>
    <ProgramRequest />
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
// import BrandsSlider from "components/BrandsSlider.vue";
import BrandsItemSlider from "./components/BrandsItemSlider.vue";
import CalculatorComponent from "components/Calculator.vue";
import ProgramRequest from "../programs/components/Request.vue";
import { cityIn } from "lvovich";

export default {
  name: "BrandPageComponent",
  data() {
    return {
      activeTab: 0,
      flickityReady: false,
      flickity: undefined,
    };
  },
  computed: {
    article() {
      let haveError = false;
      try {
        JSON.parse(this.brand.article);
      } catch (e) {
        haveError = true;
      }
      return haveError
        ? {
            blocks: [
              {
                data: {
                  text: this.brand.article || "",
                },
                type: "paragraph",
              },
            ],
          }
        : JSON.parse(this.brand.article);
    },
    description() {
      let haveError = false;
      try {
        JSON.parse(this.brand.description);
      } catch (e) {
        haveError = true;
      }
      return haveError
        ? {
            blocks: [
              {
                data: {
                  text: this.brand.description || "",
                },
                type: "paragraph",
              },
            ],
          }
        : JSON.parse(this.brand.description);
    },
    pageTitle() {
      let page = {};
      let city = this.$store.state.cities.find((c) => c && c.title_eng === this.$store.state.geoData.city);
      city = cityIn(city ? city.title : "Казань");
      if (this.$store.state.brand_page && this.$store.state.brand_page.brand) {
        page = this.$store.state.brand_page.brand;
      }
      return page ? (page.title || page.meta_title).replace(/{{ city }}/g, `в ${city}`) : "";
    },
    page() {
      return this.$store.state.brand_page;
    },
    products() {
      return this.brand.products;
    },
    brand() {
      return this.page.brand;
    },
    programs() {
      if (
        this.$store.state.brand_page &&
        this.$store.state.brand_page.brand &&
        this.$store.state.brand_page.brand.programs.length
      ) {
        const programsStringified = this.$store.state.brand_page.brand.programs.map((v) => JSON.stringify(v));
        const programs = JSON.parse(JSON.stringify(this.$store.state.brand_page.brand.programs));
        return programs
          .filter((v, i) => programsStringified.indexOf(JSON.stringify(v)) === i)
          .sort((a, b) => {
            return (a.position || 0) - (b.position || 0);
          });
      } else {
        return undefined;
      }
    },
    vehicle() {
      if (this.$store.state.brand_page && this.$store.state.brand_page.vehicle) {
        return this.$store.state.brand_page.vehicle;
      } else {
        return undefined;
      }
    },
    conditions() {
      if (this.programs && this.programs[this.activeTab] && this.programs[this.activeTab].conditions_array) {
        return this.programs[this.activeTab].conditions_array.filter(
          (c) => c.vehicle_id === parseInt(this.vehicle.id)
        );
      }
      return [];
    },
    options() {
      if (
        this.programs &&
        this.activeTab !== null &&
        this.programs[this.activeTab] &&
        this.programs[this.activeTab].options
      ) {
        return this.programs[this.activeTab].options.filter((opt) => {
          return opt.conditions_array.filter((cnd) => cnd.vehicle_id === this.vehicle.id).length;
        });
      } else {
        return [];
      }
    },
    brands() {
      if (this.brand) {
        return this.page.vehicle.brands.filter((b) => b.id !== this.brand.id);
      }
      return this.page.vehicle.brands;
    },
  },
  metaInfo() {
    let api = this.$store.state.api;
    let alternateLink = this.$store.state.alternateLink;
    let data = {};
    let city = this.$store.state.cities.find((c) => c.title_eng === this.$store.state.geoData.city);
    city = cityIn(city ? city.title : "Казань");
    if (this.$store.state.brand_page && this.$store.state.brand_page.brand) {
      data = this.$store.state.brand_page.brand;
    }
    const title = data ? (data.meta_title || data.title || "").replace(/{{ city }}/g, `в ${city}`) : "";

    let metaPageTitle = this.pageTitle;
    let metaFullPath = this.$route.fullPath;

    let parentMeta = this.$route.meta.parent.map((item, i) => {
      return item
        ? {
            "@type": "ListItem",
            position: i + 2,
            name: item.title,
            item:
              api +
              "/" +
              this.$router.resolve({
                name: item.name,
                params: item.params,
              }).href,
          }
        : "";
    });

    return {
      title,
      script: [
        {
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: title,
            description: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
          },
        },
        {
          type: "application/ld+json",
          json: {
            "@context": "http://www.schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: "1",
                name: "Главная",
                item: api,
              },
              ...parentMeta,
              {
                "@type": "ListItem",
                position: "4",
                name: metaPageTitle,
                item: api + metaFullPath,
              },
            ],
          },
        },
      ],
      link: [
        { rel: "canonical", href: alternateLink },
        { rel: "alternate", hreflang: "x-default", href: alternateLink },
      ],
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: (data.meta_keywords || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "description",
          name: "description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
        {
          vmid: "og:image",
          name: "og:image",
          content: "",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: (data.meta_description || "").replace(/{{ city }}/g, `в ${city}`),
        },
      ],
    };
  },
  components: {
    ProgramRequest,
    CalculatorComponent,
    BrandsItemSlider,
    EditorJSComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/vehicle-page.styl"
</style>
