<template>
  <Component :is="page" />
</template>

<script>
import PageNotFound from "../PageNotFound.vue";
import Page from "./page.vue";

export default {
  name: "BrandPage",
  async asyncData({ store, res, route, link }) {
    store.state.alternateLink = link;
    await store.dispatch("GET_BRAND_PAGE", {
      vehicle: route.params.vehicle,
      brand: route.params.brand,
    });
    if (res) {
      if (!store.state.brand_page.brand || !store.state.brand_page.vehicle) {
        res.status(404);
      } else {
        const isBrandInVehicle = !!store.state.brand_page.vehicle.brands.find(
          (b) => b.id === store.state.brand_page.brand.id
        );
        if (!isBrandInVehicle) {
          res.status(404);
        }
      }
    }
  },
  beforeCreate() {
    if (!this.$route.meta.parent.filter((p) => p.name === "service").length) {
      this.$route.meta.parent.push({
        title: this.$store.state.brand_page.vehicle.title,
        name: "service",
        params: {
          url: this.$route.params.vehicle,
        },
      });
    }
  },
  computed: {
    page() {
      if (!this.$store.state.brand_page.brand || !this.$store.state.brand_page.vehicle) {
        return PageNotFound;
      } else {
        const isBrandInVehicle = !!this.$store.state.brand_page.vehicle.brands.find(
          (b) => b.id === this.$store.state.brand_page.brand.id
        );
        if (!isBrandInVehicle) {
          return PageNotFound;
        } else {
          return Page;
        }
      }
    },
  },
};
</script>
