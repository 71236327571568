<template>
  <kinesis-container class="consult">
    <div class="consult__image">
      <kinesis-element type="translate" :strength="$mq === 'lg' ? 15 : 0">
        <img
          loading="lazy"
          decoding="async"
          src="/public/images/cosmonaut.png"
          alt="Отправить заявку на консультацию"
          class="consult__img"
        />
      </kinesis-element>
    </div>
    <div class="container">
      <h2 class="prg__tabs__title">Отправить заявку на консультацию</h2>
      <div class="consult__row">
        <div class="consult__fix"></div>
        <div class="consult__titles">
          <span class="consult__title">Индивидуальный подход</span>
          <span class="consult__title__dec">Подпишем договор даже в космосе</span>
        </div>
        <form class="consult__form" @submit.prevent="submitRequest">
          <div class="consult__form__group" :class="{ error: form.name.message }">
            <label for="name">Ваше имя</label>
            <input
              type="text"
              v-model="form.name.value"
              class="consult__form__input"
              id="name"
              placeholder=""
            />
            <small v-if="form.name.message">{{ form.name.message }}</small>
          </div>
          <div class="consult__form__group" :class="{ error: form.phone.message }">
            <label for="phone">Телефон или e-mail</label>
            <input
              type="text"
              v-model="form.phone.value"
              v-mask="'# (###) ###-##-##'"
              class="consult__form__input"
              id="phone"
              placeholder="_ (___) ___-__-__"
            />
            <small v-if="form.phone.message">{{ form.phone.message }}</small>
          </div>
          <div class="consult__form__group" :class="{ error: form.inn.message }">
            <label for="inn">ИНН</label>
            <input
              type="text"
              v-model="form.inn.value"
              v-if="form.inn.value && form.inn.value.length <= 12"
              v-mask="'####-#####-#'"
              class="consult__form__input"
              id="inn"
              placeholder="____-_____-_"
            />
            <input
              type="text"
              v-model="form.inn.value"
              v-else
              v-mask="'####-######-##'"
              class="consult__form__input"
              id="inn"
              placeholder="____-_____-_"
            />
            <small v-if="form.inn.message">{{ form.inn.message }}</small>
          </div>
          <div class="consult__form__actions">
            <span
              >Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных в
              соответствии с
              <router-link :to="{ name: 'page', params: { link: 'privacy-policy' } }" target="_blank"
                >Политикой в отношении обработки персональных данных ООО «Лизинг-Трейд»</router-link
              ></span
            >
            <button class="btn-dark" type="submit">
              <LoadingIndicator v-if="loading" title="Подождите" />
              <span v-else>Отправить</span>
            </button>
          </div>
        </form>
        <div class="consult__contacts">
          <span class="consult__contacts__title">СВЯЗАТЬСЯ С НАМИ:</span>
          <a :href="'tel:' + phone" class="consult__contacts__phone" v-if="phone">{{ phone }}</a>
          <span class="consult__contacts__address" v-if="company">{{ company.address }}</span>
          <span class="consult__contacts__subtitle" v-if="company">(Головной офис)</span>
        </div>
      </div>
    </div>
  </kinesis-container>
</template>

<script>
import LoadingIndicator from "components/LoadingIndicator.vue";
import { KinesisContainer, KinesisElement } from "vue-kinesis";

export default {
  name: "ProgramRequest",
  components: {
    LoadingIndicator,
    KinesisContainer,
    KinesisElement,
  },
  data() {
    return {
      loading: false,
      form: {
        name: {
          value: "",
          message: "",
        },
        phone: {
          value: "",
          message: "",
        },
        inn: {
          value: " - ",
          message: "",
        },
      },
    };
  },
  computed: {
    phone() {
      if (this.$store.state.phones && this.$store.state.phones.length) {
        return this.$store.state.phones[0].phone;
      }
      return undefined;
    },
    company() {
      if (
        this.$store.state.program_page &&
        this.$store.state.program_page.companies &&
        this.$store.state.program_page.companies.length
      ) {
        return this.$store.state.program_page.companies[0];
      } else {
        return undefined;
      }
    },
  },
  methods: {
    submitRequest() {
      if (!this.loading) {
        this.loading = true;
        this.resetValidation();
        const fd = new FormData();
        Object.keys(this.form).forEach((key) => {
          fd.append(key, this.form[key].value);
        });
        fd.append("type_id", this.$store.state.type.CODE_LEASING);
        if (![12, 14].includes(this.form.inn.value.length)) {
          this.form.inn.message = "ИНН может состоять только из 10 или 12 цифр";
          this.loading = false;
          return;
        }
        this.$store
          .dispatch("POST_CLAIM", fd)
          .then(({ data }) => {
            this.loading = false;
            if (data.message === "Ok!") {
              if (this.$gtm.enabled()) {
                window.dataLayer?.push({
                  event: "Lead",
                });
              }
              this.$swal("Спасибо! Ваша заявка успешно отправлена.", "Мы свяжемся с Вами в ближайшее время");
            } else {
              this.$swal("Заполните все обязательные поля!", "");
            }
          })
          .catch(({ response }) => {
            this.loading = false;
            if (response.status === 422) {
              // Вывод ошибок валидаций от Laravel
              Object.keys(response.data).forEach((message) => {
                Object.keys(this.form).forEach((field) => {
                  if (field === message) {
                    this.form[field].message = response.data[message][0];
                  }
                });
              });
            } else {
              this.$swal("Заполните все обязательные поля!", "");
            }
          });
      }
    },
    /**
     * Сброс сообщений от валидации laravel
     */
    resetValidation() {
      Object.keys(this.form).forEach((field) => {
        this.form[field].message = null;
      });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"
@import "~@/styles/parts/consult.styl"
</style>
