<template>
  <section class="section brands" v-if="vehicle">
    <h2 class="prg__tabs__title brands__title" v-if="title">{{ title }}</h2>
    <div class="brands__list" ref="brands">
      <router-link
        v-for="(brand, i) in brands"
        :key="i"
        :to="{
          name: 'brand-item',
          params: {
            vehicle: vehicle,
            brand: brand.brand.link,
            product: brand.link,
          },
        }"
        class="brands__item"
      >
        <img
          loading="lazy"
          decoding="async"
          :data-flickity-lazyload="getImg(brand)"
          :alt="getImgAlt(brand)"
          class="brands__image"
        />
        <h3 class="prg__conditions__name brands__name">{{ brand.title }}</h3>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  name: "BrandsSlider",
  props: {
    title: {
      type: String,
    },
    brands: {
      type: Array,
      default() {
        return [];
      },
    },
    vehicle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      current: 0,
      flickityReady: false,
      flickity: undefined,
    };
  },
  mounted() {
    const Flickity = require("flickity");
    this.flickity = new Flickity(this.$refs.brands, {
      prevNextButtons: false,
      pageDots: false,
      cellAlign: "left",
      contain: true,
      lazyLoad: 4,
      on: {
        change: (index) => {
          this.current = index;
        },
        ready: () => {
          this.flickityReady = true;
        },
      },
    });
  },
  methods: {
    getImg(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.url) {
        return this.$store.state.api + item.head_img.img.url;
      }
    },
    getImgAlt(item) {
      if (item.head_img && item.head_img.img && item.head_img.img.alt) {
        return item.head_img.img.alt;
      }
    },
  },
  computed: {
    venhiclesTitle() {},
  },
};
</script>

<style lang="stylus">
@import "~@/styles/global/global.styl"

.brands
  margin-bottom 50px
  overflow hidden

  .flickity-viewport
    overflow initial

  +below(768px)
    margin-bottom 30px

.brands__list
  border 1px solid main_gray
  border-radius 15px
  padding 30px

.brands__item
  width 25%
  margin-right 30px
  width calc((100% - 90px) / 4)
  border-radius 15px
  padding 15px
  position relative
  transition all 0.3s
  z-index 1

  &::after
    content ''
    absolute left top
    width 100%
    height 100%
    border 1px solid main_gray
    border-radius 15px
    background main_white
    transition all 0.3s
    z-index -1

  +below(980px)
    width 33.3333%
    width calc((100% - 60px) / 3)

  +below(768px)
    width 50%
    width calc((100% - 30px) / 2)

  +below(480px)
    width 80%
    margin-right 15px

  &:hover
    &::after
      border-color main_color
      transform scale(1.03)

.brand__image
  width auto
  height 98%
  object-fit contain
  object-position center
</style>
